<template>
  <div class="padding-top-layout padding-right-layout form-evidence">
    <div class="listMenu-page list-menu">
      <div class="listMenus-btn">
        <div class="form-name">{{ $t(getFormName) }}</div>
        <div style="width: 100%">
          <div class="title-btn">
            <span>{{ getLabelUploadFile}}</span>
            <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
          </div>
          <div v-if="isShowUploadFile" class="csv-file">
            <div
              class="csv-input"
              v-on:dragenter="dragEnter"
              v-on:dragover="dragOver"
              v-on:dragleave="dragLeave"
              v-on:drop="dropEvent"
            >
              <div class="import-csv">
                <img :src="getIcon('upload')" alt="" class="upload-icon" />
                <div class="csv-input-wrapper">
                  <input
                    type="file"
                    :accept="acceptFileType"
                    id="csv"
                    ref="csvInput"
                    class="d-none"
                    @change="handleAddFile"
                  />
                  <div class="csv-input-text">{{ $t('list_menu.description_drag_and_drop_the_file_here') }}</div>
                </div>
              </div>
              <common-button
                class="button-import"
                :label="$t('list_menu.button_select_files')"
                type="colored"
                @action="$refs.csvInput.click()"
                v-ripple="false"
              />
            </div>

            <div v-if="csvFilename !== ''" class="csv-filename">
              <span class="csv-filename-detail">{{ csvFilename }}</span>
              <div class="remove-icon">
                <img
                  class="remove-icon-detail"
                  :src="getIcon(isActiveCloseIcon ? 'remove-active' : 'remove')"
                  alt=""
                  @click="handleDeleteFile"
                  @mouseenter="isActiveCloseIcon = true"
                  @mouseleave="isActiveCloseIcon = false"
                />
              </div>
            </div>
          </div>

          <slot v-if="!isShowUploadFile" name="imagePreview"></slot>

          <div v-if="typeForm === 'update' || typeForm === 'create'" class="csv-download">
            <div class="csv-description">{{ $t('list_menu.label_maximum_upload_size') }}</div>
            <div v-if="typeForm === 'update' && !isUpdateFile" @click="removeImage" class="label-delele">
              {{ $t('register_data.button_delete') }}
            </div>
          </div>
        </div>
        <div v-if="isShowDetailValue" style="width: 100%">
          <div class="title-btn title-btn-select-scope">
            <span>{{ $t('idea_db_source.title_header_value_source') }}</span>
          </div>
          <div class="listMenu-btn-text">
            <input-field
              :isReadOnly="isViewForm || !!descriptionScope.detailExternalProductCode"
              class="mt-2 mb-0"
              :inputValue="formData.value"
              :isNumberType="true"
              v-model="formData.value"
            />
          </div>
        </div>

        <div v-if="isShowDetailValue" class="des-form">
          {{ $t('evidence_storage.label_description_value_source') }}
        </div>
        <div style="width: 100%">
          <div class="title-btn title-btn-select-scope">
            <span>{{ $t('idea_db_source.label_db_note') }}</span>
          </div>
          <div class="listMenu-btn-text">
            <input-field
              :isReadOnly="isViewForm"
              class="mt-2 mb-0"
              :inputValue="formData.note"
              v-model="formData.note"
              :label="$t('primary_data_merge.placeholder_note_evidence')"
            />
          </div>
        </div>

        <slot name="historyUpdate"></slot>
      </div>

      <div class="list-menu-registration">
        <div style="display: flex; gap: 8px; flex: 0 50%">
          <slot name="actionPc"></slot>
        </div>
      </div>
      
      <div class="action-fixed" ref="scrollElement">
        <div class="return__button-responsive fixed-button-bottom">
          <div class="registration-responsive">
            <slot name="actionMobile"></slot>
          </div>
        </div>
      </div>

      <notification-popup
        :dialog="dialogNotification"
        :message="$t('idea_db_source.popup_create_success')"
        @submit="navigateAfterSubmitSuccess"
      />

      <ErrorPopup :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import InputField from '@/components/products/input/InputField.vue';

export default {
  components: {
    PageTitle,
    CommonButton,
    NotificationPopup,
    ErrorPopup,
    InputField,
  },

  props: {
    typeForm: {
      type: String,
      require: true,
    },
    formData: {
      type: Object,
      default() {
        return { data: '', note: '', value: '' };
      },
    },
    descriptionScope: {
      type: Object,
      default() {
        return { category: '', scope: '' };
      },
    },
    statusDialog: {
      type: Boolean,
      default: false,
    },
    isShowDetailValue: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      dialogNotification: false,
      isLoading: false,

      messageLimit: '',
      csvFilename: '',
      csvFileData: '',
      dialogCsv: false,
      limitSize: false,
      isFixedButton: true,
      errorInputdate: '',
      dialogLimit: false,
      isUpdateFile: false,
      typeFileUpload: ['image/gif', 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel',
        'text/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint'],
      isPreviewFile: this.typeForm === 'view' || this.typeForm === 'update',
      isActiveCloseIcon: false,
      acceptFileType: 'image/*,.pdf,.csv,.xls,.xlsx,.doc,.docx,.ppt,.pptx'
    };
  },
  watch: {
    typeForm(value) {
      if (value === 'view') {
        this.isUpdateFile = false;
      }
    },
    statusDialog(value) {
      if (!value) {
        this.handleDeleteFile();
      }
    },
  },

  computed: {
    ...mapState('userData', ['currentUser', 'language', 'existDbSource']),
    getLabelUploadFile() {
      if (this.typeForm === 'view') {
        return this.$t('evidence_storage.label_upload_file');
      }
      return this.$t('evidence_storage.label_upload_file_edit');
    },
    getTitle() {
      return;
    },

    getDate() {
      return this.$t('evidence_storage.description_evidence_date', {
        year: this.descriptionScope.year,
        month: this.descriptionScope.month,
      });
    },

    isViewForm() {
      return this.typeForm === 'view';
    },

    isShowUploadFile() {
      return this.typeForm === 'create' || this.isUpdateFile;
    },

    getFormName() {
      let name = 'evidence_storage.title_create_evidence_storage';

      if (this.typeForm === 'create') name = 'evidence_storage.title_create_evidence_storage';

      if (this.typeForm === 'view') name = 'evidence_storage.title_detail_evidence_storage';

      if (this.typeForm === 'update') name = 'evidence_storage.title_edit_evidence_storage';

      return name;
    },
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),

    removeImage() {
      this.isUpdateFile = true;
      this.$emit('onRemoveImage');
    },

    navigateAfterSubmitSuccess() {
      const path = this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.SETTING;
      this.$router.push({ path: path });
    },

    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },

    submitRegisterHandler() {
      let validateForm = Object.keys(this.formData).filter((key) => (key != 'note' && this.formData[key]) === '');
      return (validateForm.length !== 0 && !this.validateDate) || this.isLoading;
    },
    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},

    handleAddFile(event) {
      if (this.formData.data) {
        this.$refs.csvInput.value = '';
        this.openDialogError();
        return;
      }

      if (event.target.files[0]) {
        const fileName = event.target.files[0].name;
        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;

        const fileExtension = fileName.split('.').pop().toLowerCase();

        if (fileExtension === 'jpeg') {
          this.limitSize = true;
          this.messageLimit = this.$t('evidence_storage.error_file_type');
          this.dialogLimit = true;
          this.$refs.csvInput.value = '';
        } else if (!this.typeFileUpload.includes(fileType) || this.endsWithIgnoreCase(fileName, '.jfif')) {
          this.limitSize = true;
          this.messageLimit = this.$t('evidence_storage.error_file_type');
          this.dialogLimit = true;
          this.$refs.csvInput.value = '';
          return;
        } else if (fileSize > 5242880) {
          this.limitSize = true;
          this.messageLimit = this.$t('evidence_storage.error_file_size');
          this.dialogLimit = true;
          this.$refs.csvInput.value = '';
          return;
        } else {
          this.csvFilename = fileName;
          const reader = new FileReader();
          this.formData.data = event.target.files[0];
          reader.readAsArrayBuffer(event.target.files[0]);
          this.$refs.csvInput.value = '';
        }
      }
    },
    async dropEvent(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.formData.data) {
        this.openDialogError();
        return;
      }
      event.preventDefault();
      const file = event.dataTransfer.files[0];

      if (!this.typeFileUpload.includes(file.type)) {
        this.limitSize = true;
        this.messageLimit = this.$t('evidence_storage.error_file_type');
        this.dialogLimit = true;
        this.$refs.csvInput.value = '';
        return;
      } else if (file?.size > 5242880) {
        this.limitSize = true;
        this.messageLimit = this.$t('evidence_storage.error_file_size');
        this.dialogLimit = true;
        this.$refs.csvInput.value = '';
        return;
      } else {
        this.csvFilename = file.name;
        this.formData.data = event.dataTransfer.files[0];
        this.$refs.csvInput.value = '';
        // TODO: call api check csv file
      }
    },
    openDialogError() {
      this.messageLimit = this.$t('evidence_storage.error_upload_other_file');
      this.dialogLimit = true;
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
      this.formData.data = '';
    },
    endsWithIgnoreCase(str, suffix) {
      return str.toLowerCase().endsWith(suffix.toLowerCase());
    },

  },
};
</script>
<style lang="scss">
.listMenu-btn-text {
  .v-text-field.v-text-field--solo {
    .v-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoMid;
    }

    .v-input__slot {
      padding: 0 16px !important;
    }
  }
}

.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
</style>
<style lang="scss" scoped>
.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    top: auto;
    width: 100%;
    margin-left: 0px;
  }
}

.form-name {
  color: $goldMid;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 177.778% */
  letter-spacing: 0.54px;
}

.des-form {
  color: $monoBlack;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 186.667% */
  letter-spacing: 0.75px;
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.form-evidence {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.list-menu--title {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */
  margin-bottom: 48px;
  letter-spacing: 0.05em;

  /* Mono/Black */

  color: $monoBlack;

  /* Inside auto layout */
  // flex: none;
  // order: 1;
  // flex-grow: 0;
}
.listMenu-page {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  flex: 1;
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;

    width: 100%;

    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .listMenus-btn {
    display: flex;
    padding: 32px 32px 48px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: #e3eded;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;

      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }

      .select-year-month {
        display: flex;
        flex-flow: column !important;
        gap: 16px;
      }
    }

    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;

      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .listMenus-btn {
    padding: 20px;
  }

  .csv-file {
    background: #f7f7f2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    margin-top: 10px;
  }

  .csv-input {
    background: $monoOffWhite;
    height: 252px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;

    .import-csv {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 164px;
      width: 100%;
      border: 1px dashed #c8deea;
      padding: 32px 8px;
      gap: 8px;

      .upload-icon {
        width: 50px;
        height: 64px;
      }

      .csv-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .csv-input-text {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.75px;
          color: $monoMid;
        }
      }
    }
  }

  .csv-filename {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $dividerBlack;

    &-detail {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      word-break: break-all;
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      padding: 0px 0px 0px 16px;
      //gap: 8px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .remove-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 60px;
      height: 60px;

      &-detail {
        cursor: pointer;
      }
    }
  }

  .csv-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    margin-top: 8px;

    .label-delele {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 4px 2px;
      width: auto;
      height: 20px;

      background: $redMid;
      border-radius: 4px;
      color: white;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.03em;
      cursor: pointer;
    }
  }

  .csv-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: $monoBlack;
    font-weight: 400;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}

.return__header-responsive {
  width: 100%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;

  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;

    .return-active {
      display: none;
    }
  }

  &:hover {
    .list-emission-return-responsive {
      .return {
        display: none;
      }

      .return-active {
        display: block;
      }
    }
  }

  &:active {
    .list-emission-return-responsive {
      .return {
        display: block;
      }

      .return-active {
        display: none;
      }
    }
  }
}

.registration-responsive {
  width: 100%;
  margin: auto;
  padding: 16px 20px;
  display: grid;
  gap: 16px;

  .common-btn {
    width: 100%;
  }
}

.action-fixed {
  display: block;
  height: 128px;
}

@include desktop {
  .list-menu--title {
    padding-left: 40px;
  }

  .return__button-responsive {
    display: none;
    max-width: calc(560px);
    margin: auto;
  }

  .fixed-button-bottom {
    left: 198px !important;
  }

  .action-fixed {
    display: none;
  }

  .listMenu-page {
    .listMenus-btn {
      display: grid;
      grid-gap: 24px;
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
      padding: 32px 32px 48px 32px;

      .listMenu-btn {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;

          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .select-year-month {
          flex-flow: row !important;
        }
      }
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 50px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }

      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;

        .list-emission-return {
          height: 20px;

          .return-active {
            display: none;
          }
        }

        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }

            .return-active {
              display: block;
            }
          }
        }

        &:active {
          .list-emission-return {
            .return {
              display: block;
            }

            .return-active {
              display: none;
            }
          }
        }
      }
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }
      }
    }

    .csv-download {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      margin-top: 8px;
    }
  }
}

::v-deep .submit-register-btn {
  .v-btn__content {
    text-transform: capitalize !important;
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .return__header,
::v-deep .return__button-responsive {
  .v-btn__content {
    div {
      text-transform: lowercase;
    }

    div:first-letter {
      text-transform: uppercase !important;
    }
  }
}
</style>
        
      